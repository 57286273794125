import React from 'react'

const Nav = () => {
  return (
  <div className="nav" id="nav">
    <h1><span>Scriv</span>doku</h1>
  </div>
  )
}

export default Nav
